import { OrderApprovalResults } from '@product-marketplace/common/models/OrderApprovalResults';
import { OrderApprovalActions } from './order-approval-actions';


export class OrderDetails {
    acknowledgement1Message: string;
    acknowledgement1: boolean;
    accountNumber: string;
    accountNumberOverride: boolean;
    accountNumberLength: number;
    adviserEntreredNetWorth: number;
    approvalHistory: OrderApprovalResults;
    buyerId: number;
    buyerName: string;
    cdsName: string;
    closingDate: string;
    commissionType: string;
    contraAccountNumber: string;
    currency: string;
    cusip: string;
    deskMarkup: number;
    deskPrice: number;
    debitBalanceOverrideSelect: string;
    debitBalanceOverrideSelectOther: string;
    email: string;
    orderReceiptDate: string;
    orderReceiptTime: string;
    fdicAcknowledged: boolean;
    fdicAcknowledgmentMessage: string;
    firmNumber: string;
    issuer: string;
    issueType: string;
    orderApprovalResults: OrderApprovalResults[];
    orderApprovalStatus: any;
    orderApprovalActions: OrderApprovalActions[];
    orderDocuments: any[];
    orderId: number;
    orderNotes: string;
    override: boolean;
    overrideNotes: string;
    priceBasisAmount: number | string;
    productId: string;
    programId: number;
    prospectusAcknowledged: boolean;
    prospectusAcknowledgmentDate: any;
    prospectusAcknowledgmentTime: any;
    prospectusAcknowledgmentText: string;
    quantity: number;
    quantityEntered: number;
    quantityMultiplier: number;
    regionalTradeDeskId: number;
    repConcession: number;
    repPrice: number;
    riskLevelRank: any;
    salesConcession: number;
    settlementDate: string;
    state: string;
    productStandardPx: string;
    structureType: string;
    term: string;
    tradeApproved: boolean;
    tradeApprovedBy: string;
    tradeApprovedNotes: string;
    tradeApprovedDate: string;
    tradeApprovedTime: string;
    tradeDate: string;
    tradeDeskId: number;
    tradeType: string;
    underlyingAssetsIn: string;
    underlyingSummary: string;
    ISIN: string;
    registrationType: string;
    revision: number;

    enableOrderApprovalAttestation: boolean;
    orderApprovalAttestationMessage: string;

    riskDisclosureAttestation: boolean;
    riskDisclosureAttestationText: string;
    productCareAttestation: boolean;
    productCareAttestationText: string;

    constructor(order: OrderDetails) {
        this.acknowledgement1Message = order.acknowledgement1Message;
        this.acknowledgement1 = order.acknowledgement1;
        this.accountNumberLength = order.accountNumberLength;
        this.accountNumberOverride = order.accountNumberOverride;
        this.accountNumber = order.accountNumber;
        this.adviserEntreredNetWorth = order.adviserEntreredNetWorth;
        this.buyerId = order.buyerId;
        this.buyerName = order.buyerName;
        this.closingDate = order.closingDate;
        this.commissionType = order.commissionType;
        this.contraAccountNumber = order.contraAccountNumber;
        this.currency = order.currency;
        this.cusip = order.cusip;
        this.cdsName = order.cdsName;
        this.deskPrice = order.deskPrice;
        this.deskMarkup = order.deskMarkup;
        this.debitBalanceOverrideSelect = order.debitBalanceOverrideSelect;
        this.debitBalanceOverrideSelectOther = order.debitBalanceOverrideSelectOther;
        this.email = order.email;
        this.fdicAcknowledged = order.fdicAcknowledged;
        this.fdicAcknowledgmentMessage = order.fdicAcknowledgmentMessage;
        this.firmNumber = order.firmNumber;
        this.orderReceiptDate = order.orderReceiptDate;
        this.orderReceiptTime = order.orderReceiptTime;
        this.issuer = order.issuer;
        this.issueType = order.issueType;
        this.orderApprovalResults = order.orderApprovalResults;
        this.orderApprovalStatus = order.orderApprovalStatus;
        this.orderId = order.orderId;
        this.override = order.override;
        this.overrideNotes = order.overrideNotes;
        this.orderDocuments = order.orderDocuments;
        this.orderNotes = order.orderNotes;
        this.productId = order.productId;
        this.programId = order.programId;
        this.prospectusAcknowledged = order.prospectusAcknowledged;
        this.prospectusAcknowledgmentDate = order.prospectusAcknowledgmentDate;
        this.prospectusAcknowledgmentTime = order.prospectusAcknowledgmentTime;
        this.prospectusAcknowledgmentText = order.prospectusAcknowledgmentText;
        this.quantity = order.quantity;
        this.quantityEntered = order.quantityEntered;
        this.quantityMultiplier = order.quantityMultiplier;
        this.regionalTradeDeskId = order.regionalTradeDeskId;
        this.repConcession = order.repConcession;
        this.repPrice = order.repPrice;
        this.riskLevelRank = +order.riskLevelRank;
        this.salesConcession = order.salesConcession;
        this.settlementDate = order.settlementDate;
        this.state = order.state;
        this.productStandardPx  = order.productStandardPx ;
        this.structureType = order.structureType;
        this.term = order.term;
        this.tradeApproved = order.tradeApproved;
        this.tradeApprovedBy = order.tradeApprovedBy;
        this.tradeApprovedNotes = order.tradeApprovedNotes;
        this.tradeApprovedDate = order.tradeApprovedDate;
        this.tradeApprovedTime = order.tradeApprovedTime;
        this.tradeDate = order.tradeDate;
        this.tradeDeskId = order.tradeDeskId;
        this.tradeType = order.tradeType;
        this.underlyingAssetsIn = order.underlyingAssetsIn;
        this.underlyingSummary = order.underlyingSummary;
        this.ISIN = order.ISIN;
        this.registrationType = order.registrationType;
        this.revision = order.revision;

        this.enableOrderApprovalAttestation = order.enableOrderApprovalAttestation;
        this.orderApprovalAttestationMessage = order.orderApprovalAttestationMessage;

        this.riskDisclosureAttestation = order.riskDisclosureAttestation;
        this.riskDisclosureAttestationText = order.riskDisclosureAttestationText;
        this.productCareAttestation = order.productCareAttestation;
        this.productCareAttestationText = order.productCareAttestationText;
    }
}
