import { NgModule } from '@angular/core';
import { LumaMonthPickerComponent } from '@common/components/date-picker/luma-month-picker/luma-month-picker.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@common/material.module';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { ProductToolsModule } from '@common/product-tools.module';
import { MiniNotificationComponent } from './mini-notifcation/mini-notification.component';
import { SplitButtonComponent } from './split-button/split-button.component';
import { CurrencyCodeDisplayComponent } from './currency-code-display/currency-code-display.component';
import { ChartLegendKeyComponent } from './chart-legend-key/chart-legend-key.component';
import { NotificationSnackbarComponent } from './snackbar-components/notification-snackbar/notification-snackbar.component';
import { ElipsesTooltipDirective } from '@common/directives/elipses-tooltip.directive';
import { PayloadErrorCardComponent } from './payload-error-card/payload-error-card.component';
import { YesNoToggleComponent } from './yes-no-toggle/yes-no-toggle.component';
import { ActiveInactiveToggleComponent } from './active-inactive-toggle/active-inactive-toggle.component';
import { MultiSelectSearchFilterComponent } from '@common/components/filters/multi-select-search-filter/multi-select-search-filter.component';
import { MultiChipFilterComponent } from '@common/components/filters/multi-chip-filter/multi-chip-filter.component';
import { SliderFilterComponent } from '@common/components/filters/slider-filter/slider-filter.component';
import { UnderlineTabsComponent } from './underline-tabs/underline-tabs.component';
import { UnderlineTabComponent } from './underline-tabs/underline-tab/underline-tab.component';
import { SaveViewComponent } from './save-view/save-view.component';
import { FilterSetComponent } from './filters/filter-set/filter-set.component';
import { IconTooltipComponent } from './icon-tooltip/icon-tooltip.component';
import { MultiSelectFilterComponent } from './filters/multi-select-filter/multi-select-filter.component';
import { FilterIndicatorChipComponent } from './filters/filter-indicator-chip/filter-indicator-chip.component';
import { SortPillsComponent } from './sort-pills/sort-pills.component';
import { ClickOutsideDirective } from '@common/directives/click-outside.directive';
import { CarouselControllerDirective } from '@common/carousel-directives/carousel-controller.directive';
import { CarouselItemDirective } from '@common/carousel-directives/carousel-item.directive';

import { ProductDetailsModalComponent } from './product-details-modal/product-details-modal.component';
import { ErrorModalComponent } from './product-details-modal/components/error-modal/error-modal.component';
import { ProductDetailsModalCoreComponent } from './product-details-modal/components/product-details-modal-core/product-details-modal-core.component';
import { ProductDetailsModalMetricsComponent } from './product-details-modal/components/product-details-modal-metrics/product-details-modal-metrics.component';
import { ProductDetailsModalPayoutChartComponent } from './product-details-modal/components/product-details-modal-payout-chart/product-details-modal-payout-chart.component';
import { ProductDetailsModalUnderlierChartComponent } from './product-details-modal/components/product-details-modal-underlier-chart/product-details-modal-underlier-chart.component';
import { LumaTooltipComponent } from '@product-marketplace/common/luma-tooltip/luma-tooltip.component';
import { SingleRangeFilterComponent } from './filters/single-range-filter/single-range-filter.component';

@NgModule({
    declarations: [
        LumaMonthPickerComponent,
        MiniNotificationComponent,
        SplitButtonComponent,
        NotificationSnackbarComponent,
        SplitButtonComponent,
        ElipsesTooltipDirective,
        PayloadErrorCardComponent,
        YesNoToggleComponent,
        ActiveInactiveToggleComponent,
        MultiSelectSearchFilterComponent,
        MultiChipFilterComponent,
        SliderFilterComponent,
        CurrencyCodeDisplayComponent,
        NotificationSnackbarComponent,
        ChartLegendKeyComponent,
        UnderlineTabsComponent,
        UnderlineTabComponent,
        SaveViewComponent,
        FilterSetComponent,
        IconTooltipComponent,
        MultiSelectFilterComponent,
        ProductDetailsModalComponent,
        ErrorModalComponent,
        LumaTooltipComponent,
        ProductDetailsModalCoreComponent,
        ProductDetailsModalMetricsComponent,
        ProductDetailsModalPayoutChartComponent,
        ProductDetailsModalUnderlierChartComponent,
        MultiSelectFilterComponent,
        FilterIndicatorChipComponent,
        SortPillsComponent,
        ClickOutsideDirective,
        CarouselControllerDirective,
        CarouselItemDirective,
        SingleRangeFilterComponent,
    ],
    exports: [
        LumaMonthPickerComponent,
        MiniNotificationComponent,
        SplitButtonComponent,
        CurrencyCodeDisplayComponent,
        NotificationSnackbarComponent,
        ChartLegendKeyComponent,
        NotificationSnackbarComponent,
        ElipsesTooltipDirective,
        PayloadErrorCardComponent,
        YesNoToggleComponent,
        ActiveInactiveToggleComponent,
        MultiSelectSearchFilterComponent,
        MultiChipFilterComponent,
        SliderFilterComponent,
        UnderlineTabComponent,
        UnderlineTabsComponent,
        SaveViewComponent,
        FilterSetComponent,
        IconTooltipComponent,
        LumaTooltipComponent,
        MultiSelectFilterComponent,
        FilterIndicatorChipComponent,
        SortPillsComponent,
        ClickOutsideDirective,
        CarouselControllerDirective,
        CarouselItemDirective,
        SingleRangeFilterComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        MatMomentDateModule,
        // TODO - Optimize Import - ZF - JH
        ProductToolsModule
    ],
})
export class SharedComponentsModule{}
