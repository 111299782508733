// Angular  imports
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MaterialModule } from '@common/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule, DatePipe } from '@angular/common';

// Dev generated components/modules
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from '@common/navigation/footer/footer.component';
import { HeaderComponent } from '@common/navigation/header/header.component';
import { GlobalNavComponent } from './common/navigation/globalNav/globalNav.component';
import { SideMenuModule } from '@common/navigation/side-menu/side-menu.module';
import { UserMenuModule } from '@common/navigation/user-menu/user-menu.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { LoginModule } from './company-modules/login/login.module';
import { DocumentService } from '@common/services/document.service';
import { NavService } from '@common/services/nav.service';
import { UpdateSnackbarComponent } from '@common/update-snackbar/update-snackbar.component';
import { ErrorModalComponent } from '@common/error-modal/error-modal.component';

import { DialogProgressSpinnerComponent } from '@common/dialog-progress-spinner/dialog-progress-spinner.component';
import { AuthInterceptor } from '@common/auth.interceptor';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiMonthPickerComponent } from '@common/multi-month-picker/multi-month-picker.component';
import { AuctionMultiMonthPickerComponent } from '@common/multi-month-picker/auction-multi-month-picker.component';
import { NotFoundComponent } from '@common/not-found/not-found.component';
import { InternalErrorInterceptor } from '@common/internal-error.interceptor';
import { SsoRequiredComponent } from './company-modules/sso-required/sso-required.component';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@environments/environment';
import { IconsModule } from './icons.module';
import { SessionService } from './session.service';
import { InitializeService } from './initializeService';
import { RestrictedAccessGuard } from '@common/services/restricted-access.guard';
import { NoScrollInputDirective } from '@common/directives/prevent-number-scroll.directive';

@NgModule({
  declarations: [
    AppComponent,
    AuctionMultiMonthPickerComponent,
    DialogProgressSpinnerComponent,
    FooterComponent,
    HeaderComponent,
    GlobalNavComponent,
    MultiMonthPickerComponent,
    NotFoundComponent,
    UpdateSnackbarComponent,
    SsoRequiredComponent,
    ErrorModalComponent,
    NoScrollInputDirective
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    DashboardModule,
    FlexLayoutModule,
    IconsModule,
    FormsModule,
    HttpClientModule,
    LoginModule,
    MaterialModule,
    SideMenuModule,
    UserMenuModule,
    HammerModule,
    ReactiveFormsModule,

  ],
  providers: [
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: () => initializeApp,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      deps: [InitializeService],
      useFactory: initializeUser,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InternalErrorInterceptor,
      multi: true,
    },
    DocumentService,
    NavService,
    CookieService,
    SessionService,
    RestrictedAccessGuard,
  ],
  bootstrap: [AppComponent],
  exports: [MaterialModule],
})
export class AppModule {}

// Due to environment variables being set during the deployment, we need to run this to trigger angular to re-evaluate the (now updated) environment variables
// sorry for the hack. -- jh 6/25/2021
function initializeApp(): Promise<any> {
  return new Promise((resolve, reject) => {
    for (const foo in environment) {
      if (environment.hasOwnProperty(foo)) {
        environment[foo] = environment[foo];
      }
    }
    resolve(null);
  });
}

export function initializeUser(
  initializeService: InitializeService
): () => Promise<void> {
  return (): Promise<void> => {
    return initializeService.initUser();
  };
}
