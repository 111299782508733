import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { UserService } from '@common/services/user.service';
import { User } from '@common/models/user';
import { SpinnerService } from './spinner.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmOrCancelModalComponent } from '@common/confirm-or-cancel-modal/confirm-or-cancel-modal.component';
import { PricingToolConfig } from '@common/models/pricing-tool-config';
import { WidgetPermissions } from '@common/services/ui.widget.permissions';
import { MenuLink } from '@common/models/menu-link';

@Injectable()
export class NavService implements OnDestroy {

  private static baseMenuLink: MenuLink = {title: 'Creation Hub', icon: 'hammer'};

  public sideNavState: Subject<string> = new Subject();
  public showSidenav: Subject<boolean> = new Subject();
  public closeSideNav: Subject<boolean> = new Subject();
  public showHeader: Subject<boolean> = new Subject();
  public showFooter: Subject<boolean> = new Subject();
  public defaultHeaderSub: BehaviorSubject<string> = new BehaviorSubject('luma');

  private userSub: Subscription;
  private pricingToolConfig$: BehaviorSubject<PricingToolConfig> =
    new BehaviorSubject({cardLink: {}, menuLink: NavService.baseMenuLink});

  constructor(private http: HttpClient,
              private userService: UserService,
              private spinnerService: SpinnerService,
              private dialog: MatDialog
  ) {
    this.userSub = this.userService.$user.subscribe((user: User) => {
      if (user) {
        this.loadPricingToolConfig();
      }
    });
  }

  get pricingToolType() {
    return this.userService.getUser()?.pricingToolType;
  }

  get userPermissions() {
    return this.userService.getPermissions();
  }

  static getColdFusionLink(page: string, section: string) {
    return `${environment.hosts.portal}/cdfg/WebPages/${page}.cfm?${section ? `section=${section}&` : ''}`;
  }

  static getColdFusionLinkAppVersion(page: string, section: string) {
    return `${environment.hosts.portal}/cdfg/app/${page}.cfm?${section ? `section=${section}&` : ''}`;
  }

  ngOnDestroy() {
    this.userSub?.unsubscribe();
  }

  private getItemLink(menuItem) {
    const { url, route } = menuItem || {};
    return route ? {route: route} : {url: url};
  }

  private toPricingToolConfig(userPermissions): PricingToolConfig {
    const dataReportingPermissions = [
      'CAN_ACCESS_GENERAL_PRICING_TOOL',
      'IS_ADMINISTRATOR',
    ];
    const defaultCardTool = 'CAN_ACCESS_GENERAL_PRICING_TOOL';
    const config: Record<string, any> = {
      CAN_ACCESS_GENERAL_PRICING_TOOL: {
        title: 'Creation Hub 1.0',
        url: NavService.getColdFusionLink('home', 'ProductCreation')
      },
      CAN_ACCESS_CREATION_HUB: {
        title: 'Creation Hub 2.0 Beta',
        url: `${environment.hosts.go}/creationhub?`
      },
      CAN_ACCESS_LUMA_LITE_PRICING_TOOL: {
        title: 'Creation Hub Lite',
        route: '/creation-hub-ubs/create-form'
      },
      CAN_ACCESS_CALENDAR_DEAL_PRICING_TOOL: {
        title: 'Calendar Deal Pricing Tool',
        route: '/creation-hub/calendar-deal-table'
      },
      DATA_REPORTING_TOOL: {
        title: 'Data Reporting',
        url: NavService.getColdFusionLink('home', 'DataReporting'),
      },
      IS_Issuer: {
        title: 'Request History',
        url: NavService.getColdFusionLink('home', 'ProductCreation'),
      },
    };

    const permissions: string[] = Object.keys(config).filter((key) => userPermissions?.includes(key));
    
    dataReportingPermissions.some((p) => userPermissions?.includes(p)) &&
      permissions.push('DATA_REPORTING_TOOL');

    if (permissions.length === 1) {
      const link = this.getItemLink(config[permissions[0]]);
      return {
        cardLink: {...link},
        menuLink: {...NavService.baseMenuLink, ...link}
      };
    } else {
      const children: any[] = [];
      permissions.forEach((p) => {
        config[p]?.children
          ? children.push(...config[p].children)
          : children.push(config[p]);
      });
      const key = permissions[defaultCardTool] ? defaultCardTool : permissions[0];
      const link = this.getItemLink(config[key]);
      return {
        cardLink: {...link},
        menuLink: {...NavService.baseMenuLink, children: children}
      };
    }
  }

  getPricingToolConfigObservable(): Observable<PricingToolConfig> {
    return this.pricingToolConfig$.asObservable();
  }

  navigateWithCst(url: string) {
    this.spinnerService.showSpinner();
    this.userService.generateCst().subscribe(cst => {
      if (cst) {
        window.location.href = (`${url}cst=${cst}`);
      } else {
        this.spinnerService.hideSpinner();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = ['confirmation-dialog', 'l-w400'];
        dialogConfig.data = {
          message: 'Unable to navigate to the requested page at this time.',
          title: 'System Error',
          singleButton: true,
          lumaContact: true
        };
        this.dialog.open(ConfirmOrCancelModalComponent, dialogConfig);
        console.error('No CST. Unable to navigate to url ' + url);
      }
    });
  }

  private loadPricingToolConfig(): void {
    const config = this.toPricingToolConfig(this.userPermissions);
    this.pricingToolConfig$.next(config);
  }

  public handleDashboardNavigation(url): boolean {
    const generateAppUrl = (route: string,) => {
      const baseUrl = window.origin
        .replace('http://', 'https://')
        .replace('localhost:4200', 'go.bdev.lumafintech.com')
        .replace('//app.', '//go.')
        .replace('//go.', '//go.')
        .replace('//an.', '//go.')
        .replace('//ch.', '//go.')
        .replace('//lm.', '//go.')
        .replace('//pl.', '//go.')
        .replace('//pm.', '//go.');

      return `${baseUrl}${route}`;
    };
    // if url does not exist, the application will route to dashboard as default component, !url covers this scenario
    if (this.userService.getUserPermissions()?.includes(WidgetPermissions.MenuCanViewNewDashboard) && (!url || url === 'dashboard')) {
      window.location.href = generateAppUrl('/dashboard');
    } else {
      return true;
    }
  }
}
