import { Component, OnDestroy } from '@angular/core';
import { UserService } from '@common/services/user.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { NavService } from '@common/services/nav.service';
import { animateText, onSideNavChange } from '../../../animations/animations';
import { User } from '@common/models/user';
import { Subscription } from 'rxjs';
import { IncompleteTrainingsDialogComponent } from 'src/app/dashboard/incomplete-trainings-dialog/incomplete-trainings-dialog.component';
import { Router } from '@angular/router';
import { WidgetPermissions } from '@common/services/ui.widget.permissions';
import { MarketplaceProductService } from '@common/services/marketplace-product.service';
import { ConfirmOrCancelModalComponent } from '@common/confirm-or-cancel-modal/confirm-or-cancel-modal.component';
import { environment } from '@environments/environment';
import { PricingToolConfig } from "@common/models/pricing-tool-config";
import { MenuLink } from "@common/models/menu-link";

interface TreeNode {
  title: string;
  icon?: string;
  url?: string;
  route?: string;
  expandable: boolean;
  level: number;
  expanded: boolean;
}

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  animations: [onSideNavChange, animateText]
})
export class SideMenuComponent implements OnDestroy {
  userSub: Subscription;
  user: User;
  closeNavSub: Subscription;
  public sideNavState = 'close';
  public sideNavOpen = false;
  public linkText = false;
  private pricingToolConfig : PricingToolConfig = null;

  treeControl = new FlatTreeControl<TreeNode>(
    node => node.level,
    node => node.expandable
  );
  treeFlattener = new MatTreeFlattener<MenuLink, TreeNode>(
    (node: MenuLink, level: number) => {
      return {
        title: node.title,
        icon: node.icon,
        url: node.url,
        route: node.route,
        expandable: node.children != null && node.children.length > 0,
        level,
        expanded: false
      };
    },
    node => node.level,
    node => node.expandable,
    node => node.children
  );
  menuLinks = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(private userService: UserService,
              private navService: NavService,
              public dialog: MatDialog,
              private marketplaceProductService: MarketplaceProductService,
              private router: Router) {
    this.loadDefaultMenuLinks();
    this.closeNavSub = this.navService.closeSideNav.subscribe(x => { this.sideNavOpen = true; this.onSidenavToggle(); });
    this.userSub = this.userService.$user.subscribe((u: User) => {
      if (u != null) {
        this.user = u;
        navService
          .getPricingToolConfigObservable()
          .subscribe(pricingToolConfig => {
            this.pricingToolConfig = pricingToolConfig;
            this.loadMenuLinks(u);
          });
      } else {
        this.loadDefaultMenuLinks();
      }
    });
  }

  loadDefaultMenuLinks() {
    this.menuLinks.data = [
      {
        title: 'Dashboard',
        icon: 'tachometer-alt',
        route: '/dashboard'
      }
    ];
  }

  loadMenuLinks(user: User) {
    const allMenuLinks = [
      {
        title: 'Dashboard',
        icon: 'tachometer-alt',
        route: '/dashboard'
      },
      {
        title: 'Learning Center',
        icon: 'graduation-cap',
        route: '/learning-center',
      },
      this.pricingToolConfig.menuLink,
      {
        title: 'Product Marketplace',
        icon: 'store',
        children: [
            ...this.generateDynamicMarketplaceLinks(),
          {
            title: 'Annuity Order Management',
            permissions: [WidgetPermissions.MenuMarketplaceAnnuity],
            route: 'product-marketplace/annuity-product-orders'
          },
          {
            title: 'Account Upload',
            permissions: [WidgetPermissions.MenuPmConfigurationsKycRulesUpload],
            route: 'product-marketplace/account-file-upload'
          },
          ...this.showMarketplaceSettings(),
          {
            title: 'Wholesaler Mapping',
            permissions: [WidgetPermissions.MenuPmWholesalerMapping],
            route: '/product-marketplace/wholesaler-mapping'
          }
        ]
      },
      {
        title: 'Lifecycle Manager',
        icon: 'recycle',
        permissions: [WidgetPermissions.MenuLifecycleManager, WidgetPermissions.AccessLifecycleManagerAnnuities],
        route: '/lifecycle-manager',
      },
      {
        title: 'Notification Center',
        icon: 'envelope',
        permissions: [WidgetPermissions.MenuNotificationCenter],
        route: '/notification-center',
      },
      {
        title: 'Admin',
        icon: 'user-shield',
        permissions: [WidgetPermissions.MenuAdmin],
        children: [
          {
            title: 'Company Administration',
            route: 'company-administration',
            permissions: [WidgetPermissions.MenuAdmin]
          },
          {
            title: 'Company Relationship Mapping',
            permissions: [WidgetPermissions.MenuAdminCompanyRelationshipMapping],
            route: '/admin/company-administration-relationship-mapping'
          },
          {
            title: 'Company Configuration',
            route: '/admin/company-administration-configuration'
          },
          {
            title: 'Users',
            permissions: [WidgetPermissions.MenuAdminUsers],
            url: NavService.getColdFusionLinkAppVersion('AccountsContainer', null)
          },
          {
            title: 'Return Description Tool',
            permissions: [WidgetPermissions.MenuAdminReturnDescription],
            url: NavService.getColdFusionLink('productComparisionReturnDescription', null)
          },
          {
            title: 'Web Content',
            permissions: [WidgetPermissions.MenuAdminWebContent],
            url: NavService.getColdFusionLink('salesmarketingspace/webcontent', null)
          },
          {
            title: 'Program Issuer Map',
            permissions: [WidgetPermissions.MenuAdminProgramIssuerMap],
            url: NavService.getColdFusionLink('salesmarketingspace/programtoissuermap', null)
          },
          {
            title: 'Focus List Video',
            permissions: [WidgetPermissions.MenuAdminWebFocusListVideo],
            url: NavService.getColdFusionLink('inventory/videostructurecontent', null)
          },
          {
            title: 'Marketing Email Tracking Upload',
            permissions: [WidgetPermissions.MenuAdminMarketingEmailTrackingUpload],
            url: NavService.getColdFusionLink('home', 'MarketingEmailTracking')
          },
          {
            title: 'All Maturing Products',
            permissions: [WidgetPermissions.MenuAdminAllMaturingProducts],
            url: NavService.getColdFusionLink('home', 'AllMaturingProducts')
          },
          {
            title: 'Compliance Trainings Export',
            permissions: [WidgetPermissions.MenuAdminComplianceTrainingsExport],
            url: NavService.getColdFusionLink('home', 'ComplainceTrainingExport')
          },
          {
            title: 'Comparable Structure Types Configuration',
            permissions: [WidgetPermissions.MenuAdminComparableStructureTypesConfiguration],
            url: NavService.getColdFusionLink('home', 'ComparableStructureConfiguration')
          },
          {
            title: 'PCT Issuer Preferences',
            permissions: [WidgetPermissions.MenuAdminPCTIssuerPreferences],
            url: NavService.getColdFusionLink('home', 'IssuerPreference')
          },
          {
            title: 'PCT Client Preferences',
            permissions: [WidgetPermissions.MenuAdminPCTClientPreferences],
            url: NavService.getColdFusionLink('home', 'ClientPreference')
          },
          {
            title: 'Clients',
            permissions: [WidgetPermissions.MenuAdminClients],
            url: NavService.getColdFusionLink('clientsContainer', null)
          },
          {
            title: 'Daily Orders Export',
            permissions: [WidgetPermissions.MenuAdminDailyOrdersExport],
            url: NavService.getColdFusionLink('ordersExport', null)
          },
          {
            title: 'Order Email Notifications',
            permissions: [WidgetPermissions.MenuAdminOrderEmailNotifications],
            url: NavService.getColdFusionLinkAppVersion('emailNotificationContainer', null)
          },
          {
            title: 'Historical Emails',
            permissions: [WidgetPermissions.MenuAdminHistoricalEmails],
            route: '/admin/email-info/overview',
          },
          {
            title: 'File Upload',
            permissions: [WidgetPermissions.MenuAdminFileUpoad],
            route: '/admin/file-upload',
          },
          {
            title: 'Audit Records',
            permissions: [WidgetPermissions.MenuAdminHistoricalEmails], // TODO: add new permission
            route: '/admin/audit-records',
          },
          {
            title: 'Wholesaler Definition',
            permissions: [WidgetPermissions.MenuAdminWholesalerDefinition],
            route: '/admin/wholesaler-definition',
          },
          {
            title: 'Wholesaler Mapping',
            permissions: [WidgetPermissions.MenuAdminWholesalerMapping],
            route: '/admin/wholesaler-mapping',
          },
          {
            title: 'Product Upload',
            permissions: [WidgetPermissions.ProductCenter],
            route: '/product-upload'
          },
          {
            title: 'Disclosure Document Upload',
            permission: WidgetPermissions.MarketplaceAdminFields,
            route: 'product-marketplace/disclosure-document-upload'
          },
          {
            title: 'Market Data Maintainence',
            permissions: [WidgetPermissions.MenuAdmin],
            route: '/admin/market-data-maintainence'
          },
          {
            title: 'Issuer Confirmation',
            route: '/admin/issuer-confirmation'
          },
          {
            title: 'Email Configuration',
            permissions: [WidgetPermissions.MenuAdmin],
            route: '/admin/email-config'
          },
          {
            title: 'Find RFQ',
            permissions: [WidgetPermissions.MenuAdmin],
            route: '/admin/find-rfq'
          },
          {
            title: 'Add CH Request to PDW',
            permissions: [WidgetPermissions.MenuAdmin],
            url: NavService.getColdFusionLink('adminAddProduct', null)
          },
          {
            title: 'Product Maintenance',
            permissions: [WidgetPermissions.ProductMaintenance],
            route: '/admin/product-maintenance'
          }
        ]
      }
    ];
    this.menuLinks.data = this.filterLinks(allMenuLinks, user);
  }


  filterLinks(links: MenuLink[], user): MenuLink[] {
    if (links != null) {
      const result = links.filter(link => {
        // todo REMOVE_OR_ENHANCE--@JACOB
        // todo This could be modified to call some generic feature flag check function, maybe a property on menulink, but that's another story
        if(link.route === 'company-administration' && environment.production) {
          return false;
        }
        return link.permissions == null || link.permissions.some((p) => user.canAccess(p));
      });
      result.forEach(link => {
        link.children = this.filterLinks(link.children, user);
      });
      return result;
    } else {
      return null;
    }
  }

  hasChild(_: number, node: TreeNode) {
    return node.expandable;
  }

  onSidenavToggle() {
    this.sideNavOpen = !this.sideNavOpen;
    if (this.sideNavOpen) {
      this.updateSidenavState('open');
    } else {
      this.updateSidenavState('close');
      this.treeControl.collapseAll();
    }

    setTimeout(() => {
      this.linkText = this.sideNavOpen;
    }, 200);
  }

  onSideNavToggleAfterClick(node) {
    if(this.isMarketplaceLink(node.title)) {
      this.marketplaceNavigation(node);
    } else if(this.isLearningCenterLink(node.title)) {
      this.learningCenterNavigation(node);
    } else {
      if (this.sideNavOpen === true) {
        this.sideNavOpen = false;
      }
      if (this.sideNavOpen) {
        this.updateSidenavState('open');
      } else {
        this.updateSidenavState('close');
        this.treeControl.collapseAll();
      }
      setTimeout(() => {
        this.linkText = this.sideNavOpen;
      }, 200);
      if(node.route) {
        this.router.navigate([node.route]);
      } else if(node.url) {
        this.navService.navigateWithCst(node.url);
      }
    }
  }

  expandCollapseTree(node: TreeNode) {
    if (!this.sideNavOpen) {
      // when sidenav closed and expandable node is clicked, always open sideNav and expand the node
      this.onSidenavToggle();
      this.treeControl.expand(node);
      node.expanded = true;
    } else {
      node.expanded = !node.expanded;
    }

    if (this.treeControl.dataNodes.find(n => n.expanded)) {
      this.updateSidenavState('openExpanded');
    } else {
      this.updateSidenavState('open');
    }
  }

  updateSidenavState(state: string) {
    this.sideNavState = state;
    this.navService.sideNavState.next(state);
  }
  ngOnDestroy() {
    this.userSub?.unsubscribe();
    this.closeNavSub?.unsubscribe();
  }

  isMarketplaceLink(title: string): boolean {
    return (title === 'Structured Products' || title === 'Structured Products 2.0' || title === 'Structured Product Orders');
  }


  isLearningCenterLink(title: string): boolean {
    return title === 'Learning Center';
  }

  learningCenterNavigation(node) {
    if(this.user?.canAccess(WidgetPermissions.AccessLearningCenter)) {
      this.router.navigate([node.route]);
    } else {
      const dialogConfig: MatDialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        message: 'Your account does not have access to the learning center.',
        title: 'No Access',
        singleButton: true,
      };
      dialogConfig.panelClass = ['confirmation-dialog', 'l-w400'];
      this.dialog.open(ConfirmOrCancelModalComponent, dialogConfig);
    }
  }

  marketplaceNavigation(node) {
    const trainingData = this.userService.getUser().trainingData;
    if (trainingData?.complete) {
      this.router.navigate([node.route]);
      this.navService.closeSideNav.next(true);
    } else {
      const dialogConfig: MatDialogConfig = new MatDialogConfig();
      dialogConfig.panelClass = ['confirmation-dialog', 'l-w500'];
      this.dialog.open(IncompleteTrainingsDialogComponent, dialogConfig);
    }
  }

    generateDynamicMarketplaceLinks() {
        const trainingData = this.userService.getUser().trainingData;
        const marketplaceMenus = [];
        const spProductLinks = [
            {
                title: 'Structured Products',
                route: '/product-marketplace/structured-products'
            },
        ];

        const annuityProductLink = {
            title: 'Annuities',
            permissions: [WidgetPermissions.MenuMarketplaceAnnuity],
            route: 'product-marketplace/annuity-products'
        };

        const spProductOrders = {
            title: 'Order Management',
            route: 'product-marketplace/structured-product-orders'
        };

        if (trainingData?.isAnnuityOnly()) {
            marketplaceMenus.push(annuityProductLink);
        } else if (trainingData.isStructuredProductsOnly()) {
            marketplaceMenus.push(...spProductLinks, spProductOrders);
        } else {
            marketplaceMenus.push(...spProductLinks, annuityProductLink, spProductOrders);
        }

        return marketplaceMenus;
    }

    showMarketplaceSettings() {
      const marketplaceSettingsLink = {
        title: 'Marketplace Settings',
        permissions: [WidgetPermissions.MenuMarketplaceSettings],
        route: '/marketplace-settings'
      };
      const trainingData = this.userService.getUser().trainingData;
      return trainingData?.isAnnuityOnly() ? [] : [marketplaceSettingsLink];
    }
}
