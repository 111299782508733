import { COMPARATOR_TYPES, SortDirection, SortInfo } from '../../../../../sort-lib/sort-utilities';


export const annuityMarketplaceSortInfos: SortInfo[] = [
  {
    sortName: 'Carrier',
    dataProperty: 'carrier',
    isDefault: true,
    comparatorType: COMPARATOR_TYPES.BASIC
  },
  {
    sortName: 'Product Name',
    dataProperty: 'productName',
    comparatorType: COMPARATOR_TYPES.BASIC
  },
  {
    sortName: 'Surrender Years',
    dataProperty: 'surrenderYears',
    comparatorType: COMPARATOR_TYPES.BASIC
  },
  {
    sortName: 'M&E',
    dataProperty: 'mefee',
    comparatorType: COMPARATOR_TYPES.BASIC
  },
  {
    sortName: 'Minimum Premium',
    dataProperty: 'minPremium',
    comparatorType: COMPARATOR_TYPES.BASIC
  },
  {
    sortName: 'Maximum Premium',
    dataProperty: 'maxPremium',
    comparatorType: COMPARATOR_TYPES.BASIC
  },
  {
    sortName: 'Addon Premium',
    dataProperty: 'addOnPremium',
    comparatorType: COMPARATOR_TYPES.BASIC
  },
  {
    sortName: 'Income Rider',
    dataProperty: 'glwb',
    comparatorType: COMPARATOR_TYPES.YES_NO
  },
];


export const savedConfigurationSortInfos: SortInfo[] = [
  {
    sortName: 'Carrier',
    dataProperty: 'carrier',
    isDefault: true,
    comparatorType: COMPARATOR_TYPES.BASIC
  },
  {
    sortName: 'Product Name',
    dataProperty: 'productName',
    comparatorType: COMPARATOR_TYPES.BASIC
  },
  // Client Name	Alphabetically todo: what is this?
  // Date Saved	Chronologically todo: what is this?
];

export const annuityRateSortInfos: SortInfo[] = [
  {
    sortName: 'Carrier',
    dataProperty: 'carrier',
    isDefault: true,
    comparatorType: COMPARATOR_TYPES.BASIC,
    sortDirection: SortDirection.asc
  },
  {
    sortName: 'Product Name',
    dataProperty: 'productName',
    comparatorType: COMPARATOR_TYPES.BASIC,
    sortDirection: SortDirection.asc
  },
  {
    sortName: 'Surrender Years',
    dataProperty: 'surrenderYears',
    comparatorType: COMPARATOR_TYPES.BASIC,
    sortDirection: SortDirection.desc
  },
  {
    sortName: 'Cap Rate',
    dataProperty: 'capRate',
    comparatorType: COMPARATOR_TYPES.CUSTOM_AN_RATE,
    sortDirection: SortDirection.desc
  },
  {
    sortName: 'Fixed Rate',
    dataProperty: 'fixedRate',
    comparatorType: COMPARATOR_TYPES.CUSTOM_AN_RATE,
    sortDirection: SortDirection.desc
  },
  {
    sortName: 'Par Rate',
    dataProperty: 'parRate',
    comparatorType: COMPARATOR_TYPES.CUSTOM_AN_RATE,
    sortDirection: SortDirection.desc
  },
  {
    sortName: 'Spread Rate',
    dataProperty: 'spread',
    comparatorType: COMPARATOR_TYPES.CUSTOM_AN_RATE,
    sortDirection: SortDirection.desc
  },
  {
    sortName: 'Perf. Trigger Credit',
    dataProperty: 'performanceTriggerCredit',
    comparatorType: COMPARATOR_TYPES.CUSTOM_AN_RATE,
    sortDirection: SortDirection.desc
  },
  {
    sortName: 'Buffer',
    dataProperty: 'buffer',
    comparatorType: COMPARATOR_TYPES.BASIC,
    sortDirection: SortDirection.desc
  },
  {
    sortName: 'Floor',
    dataProperty: 'floor',
    comparatorType: COMPARATOR_TYPES.BASIC,
    sortDirection: SortDirection.desc
  },
  {
    sortName: 'Guarantee Term',
    dataProperty: 'term',
    comparatorType: COMPARATOR_TYPES.CUSTOM_AN_TERM,
    sortDirection: SortDirection.desc
  },
  {
    sortName: 'Base Rate',
    dataProperty: 'baseRate',
    comparatorType: COMPARATOR_TYPES.BASIC,
    sortDirection: SortDirection.desc
  },
  {
    sortName: 'GMIR',
    dataProperty: 'gmir',
    comparatorType: COMPARATOR_TYPES.BASIC,
    sortDirection: SortDirection.desc
  },
  {
    sortName: 'Premium Bonus',
    dataProperty: 'hasPremiumBonus',
    comparatorType: COMPARATOR_TYPES.BASIC,
    sortDirection: SortDirection.desc
  },
  {
    sortName: 'Interest Rate Bonus',
    dataProperty: 'hasInterestBonus',
    comparatorType: COMPARATOR_TYPES.BASIC,
    sortDirection: SortDirection.desc
  },
  {
    sortName: '1st Year Yield',
    dataProperty: 'firstYearYield',
    comparatorType: COMPARATOR_TYPES.BASIC,
    sortDirection: SortDirection.desc
  },
  {
    sortName: 'Guarantee Period Yield',
    dataProperty: 'guaranteePeriodYield',
    comparatorType: COMPARATOR_TYPES.BASIC,
    sortDirection: SortDirection.desc
  },
  {
    sortName: 'Surrender Period Yield',
    dataProperty: 'surrenderPeriodYield',
    comparatorType: COMPARATOR_TYPES.BASIC,
    sortDirection: SortDirection.desc
  },
  {
    sortName: 'Guarantee Period',
    dataProperty: 'formattedTermPeriod',
    comparatorType: COMPARATOR_TYPES.CUSTOM_AN_TERM,
    sortDirection: SortDirection.desc
  },
  {
    sortName: 'Rate Effective Date',
    dataProperty: 'effectiveDate',
    comparatorType: COMPARATOR_TYPES.DATE,
    sortDirection: SortDirection.asc
  },
];







