import { Validators, FormArray, FormControl, FormBuilder } from '@angular/forms';
import { OrderDetails } from '@product-marketplace/common/models/order-details';
import { OrderFileForm } from './order-file-form.model';

export class OrderForm {
    acknowledgement1 = new FormControl();
    acknowledgement1Message = new FormControl();
    accountNumber = new FormControl();
    accountNumberOverride = new FormControl();
    adviserEntreredNetWorth = new FormControl();
    accountNumberLength = new FormControl();
    buyerId = new FormControl();
    buyerName = new FormControl();
    closingDate = new FormControl();
    commissionType = new FormControl();
    salesConcession = new FormControl();
    cdsName = new FormControl();
    contraAccountNumber = new FormControl();
    currency = new FormControl();
    cusip = new FormControl();
    ISIN = new FormControl();
    registrationType = new FormControl();
    debitBalanceOverrideSelect = new FormControl();
    debitBalanceOverrideSelectOther = new FormControl();
    deskMarkup = new FormControl();
    deskPrice = new FormControl();
    email = new FormControl();
    fdicAcknowledged = new FormControl();
    fdicAcknowledgmentMessage = new FormControl();
    firmNumber = new FormControl();
    orderReceiptDate = new FormControl();
    orderReceiptTime = new FormControl();
    issuer = new FormControl();
    issueType = new FormControl();
    orderApprovalStatus = new FormControl();
    orderDocuments = new FormArray([]);
    orderId = new FormControl();
    orderNotes = new FormControl();
    override = new FormControl();
    overrideNotes = new FormControl();
    productId = new FormControl();
    programId = new FormControl();
    prospectusAcknowledged = new FormControl();
    prospectusAcknowledgmentDate = new FormControl();
    prospectusAcknowledgmentTime = new FormControl();
    prospectusAcknowledgmentText = new FormControl();
    quantity = new FormControl();
    quantityMultiplier = new FormControl();
    quantityEntered = new FormControl();
    regionalTradeDeskId = new FormControl();
    revision = new FormControl();
    repConcession = new FormControl();
    repPrice = new FormControl();
    riskLevelRank = new FormControl();
    settlementDate = new FormControl();
    productStandardPx  = new FormControl();
    structureType = new FormControl();
    term = new FormControl();
    tradeApproved = new FormControl();
    tradeApprovedBy = new FormControl();
    tradeApprovedNotes = new FormControl();
    tradeApprovedDate = new FormControl();
    tradeApprovedTime = new FormControl();
    tradeDate = new FormControl();
    tradeDeskId = new FormControl();
    tradeType = new FormControl();
    underlyingAssetsIn = new FormControl();
    underlyingSummary = new FormControl();

    enableOrderApprovalAttestation = new FormControl();
    orderApprovalAttestationMessage = new FormControl();

    riskDisclosureAttestation = new FormControl();
    riskDisclosureAttestationText = new FormControl();
    productCareAttestation = new FormControl();
    productCareAttestationText = new FormControl();

    constructor(order: OrderDetails, fb: FormBuilder) {
        if(order.acknowledgement1Message) {
            this.acknowledgement1.setValue(order.acknowledgement1)
            this.acknowledgement1Message.setValue(order.acknowledgement1Message);
        }
        this.accountNumber.setValue(order.accountNumber);
        this.accountNumberOverride.setValue(order.accountNumberOverride);
        this.accountNumber.setValidators([Validators.required]);
        this.accountNumberLength.setValue(order.accountNumberLength);
        this.buyerId.setValue(order.buyerId);
        this.buyerId.setValidators([Validators.required]);
        this.buyerName.setValue(order.buyerName);
        this.debitBalanceOverrideSelect.setValue(order.debitBalanceOverrideSelect);
        this.debitBalanceOverrideSelectOther.setValue(order.debitBalanceOverrideSelectOther);
        this.orderReceiptTime.setValue(order.orderReceiptTime);
        this.orderNotes.setValue(order.orderNotes);
        this.quantity.setValue(order.quantity);
        this.quantityMultiplier.setValue(order.quantityMultiplier);
        this.quantityEntered.setValue(order.quantityEntered);
        this.quantityEntered.setValidators([Validators.required, Validators.min(1)]);
        this.cdsName.setValue(order.cdsName);
        this.commissionType.setValue(order.commissionType);
        this.contraAccountNumber.setValue(order.contraAccountNumber);
        this.currency.setValue(order.currency);
        this.cusip.setValue(order.cusip);
        this.ISIN.setValue(order.ISIN);
        this.registrationType.setValue(order.registrationType);
        this.deskMarkup.setValue(order.deskMarkup);
        this.deskPrice.setValue(order.deskPrice);
        this.email.setValue(order.email);
        this.fdicAcknowledged.setValue(order.fdicAcknowledged);
        this.fdicAcknowledgmentMessage.setValue(order.fdicAcknowledgmentMessage);
        this.firmNumber.setValue(order.firmNumber);
        this.issuer.setValue(order.issuer);
        this.issueType.setValue(order.issueType);
        this.orderApprovalStatus.setValue(order.orderApprovalStatus);
        this.orderId.setValue(order.orderId);
        this.override.setValue(order.override);
        this.overrideNotes.setValue(order.overrideNotes);
        this.productId.setValue(order.productId);
        this.programId.setValue(order.programId);
        this.prospectusAcknowledged.setValue(order.prospectusAcknowledged);
        if (order.prospectusAcknowledgmentDate) {
            const d = OrderForm.constructDateFromValue(order.prospectusAcknowledgmentDate);
            this.prospectusAcknowledgmentDate.setValue(d);
        }
        if (order.prospectusAcknowledgmentTime) {
            this.prospectusAcknowledgmentTime.setValue(order.prospectusAcknowledgmentTime);
        }
        this.prospectusAcknowledgmentText.setValue(order.prospectusAcknowledgmentText);
        if (order.orderReceiptDate) {
            const d = OrderForm.constructDateFromValue(order.orderReceiptDate);
            this.orderReceiptDate.setValue(d);
        }
        this.repConcession.setValue(order.repConcession);
        this.repPrice.setValue(order.repPrice);
        this.regionalTradeDeskId.setValue(order.regionalTradeDeskId);
        this.revision.setValue(order.revision);
        this.riskLevelRank.setValue(order.riskLevelRank);
        this.salesConcession.setValue(order.salesConcession);
        this.settlementDate.setValue(order.settlementDate);
        this.productStandardPx .setValue(order.productStandardPx );
        this.structureType.setValue(order.structureType);
        this.term.setValue(order.term);
        this.tradeApproved.setValue(order.tradeApproved);
        this.tradeApprovedBy.setValue(order.tradeApprovedBy);
        this.tradeApprovedDate.setValue(order.tradeApprovedDate);
        this.tradeApprovedNotes.setValue(order.tradeApprovedNotes);
        this.tradeApprovedTime.setValue(order.tradeApprovedTime);
        this.tradeDate.setValue(order.tradeDate);
        this.tradeDeskId.setValue(order.tradeDeskId);
        this.tradeType.setValue(order.tradeType);
        this.underlyingAssetsIn.setValue(order.underlyingAssetsIn);
        this.underlyingSummary.setValue(order.underlyingSummary);

        if (order.adviserEntreredNetWorth) {
            this.adviserEntreredNetWorth.setValue(order.adviserEntreredNetWorth);
        } else {
            this.adviserEntreredNetWorth.setValue(0);
        }
        if (order.closingDate) {
            this.closingDate.setValue(order.closingDate);
        }
        if (order.orderDocuments) {
            for (const doc of order.orderDocuments) {
                this.orderDocuments.push(fb.group(new OrderFileForm(doc)));
            }
        }

        this.enableOrderApprovalAttestation.setValue(order.enableOrderApprovalAttestation);
        this.orderApprovalAttestationMessage.setValue(order.orderApprovalAttestationMessage);

        this.riskDisclosureAttestation.setValue(order.riskDisclosureAttestation);
        this.riskDisclosureAttestationText.setValue(order.riskDisclosureAttestationText);
        this.productCareAttestation.setValue(order.productCareAttestation);
        this.productCareAttestationText.setValue(order.productCareAttestationText);
    }

    private static constructDateFromValue(val) {
        const d = new Date(val);
        d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
        d.setHours(0);
        d.setMilliseconds(0);
        d.setSeconds(0);
        return d;
    }
}
